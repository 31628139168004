import React from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import { Accordion, Card } from "react-bootstrap";
//import Accordion from "react-bootstrap/Accordion";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="wid90 mt-6 wid100Mobile px-md-2 px-3 mx-auto">
        <span className="d-block fontSize44px boldSix blackKsb text-center mb-5">
          Welcome to Escape
        </span>
        <span className="text-center wid90 wid100Mobile mx-auto boldFour d-block lh-lg blackKsb fontSize20px mb-5">
          This Privacy Policy Agreement (hereinafter referred to as the
          "Agreement") is between Escape Technologies Limited (hereinafter
          referred to as "we," "us," or "our") and the user (hereinafter
          referred to as "you" or "your") of Escape mobile
          Application(hereinafter referred to as the "App"). This Agreement
          governs the collection, use, storage, sharing, and protection of your
          personal information when you access and use the App. By installing,
          accessing, or using the App, you acknowledge that you have read,
          understood, and agreed to the terms of this Privacy Policy.
        </span>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Information We Collect
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  Personal Information: We may collect personal information that
                  you voluntarily provide when you sign up for an account or use
                  certain features of the App. This may include but is not
                  limited to your name, email address, phone number, date of
                  birth, and any other data required for providing our services.
                </li>
                <li>
                  Non-Personal Information: We may also collect non-personal
                  information, such as device information (e.g., device type,
                  operating system), app usage data, IP address, and loaction,
                  to improve our services and enhance the user experience.
                  Specifically, we collect location data to enabale us ensure
                  that transctions are carried out within Nigeria and without
                  the use of VPN to help us mitigate fraudulent activities.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Use of Information
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  Providing Services: We use the information collected to
                  deliver the services offered through the App, process
                  transactions, and respond to your inquiries and requests.
                </li>
                <li>
                  Personalization: We may use your information to personalize
                  your experience with the App and tailor our services to meet
                  your preferences and needs.
                </li>
                <li>
                  Communication: We may use your contact information to send you
                  relevant updates, promotional materials, and important notices
                  regarding the App and our services. You can opt-out of these
                  communications at any time
                </li>
                <li>
                  Analytics: We may use aggregated and anonymized data for
                  analytical purposes to improve the performance, features, and
                  functionality of the App.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Data Security
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We implement reasonable technical and organizational measures
                  to protect your personal information from unauthorized access,
                  loss, alteration, or disclosure. However, no data transmission
                  over the internet or mobile networks can be guaranteed to be
                  completely secure, and we cannot guarantee the absolute
                  security of your data.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Data Sharing
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  Third-Party Service Providers: We may share your personal
                  information with third-party service providers who assist us
                  in operating the App and providing our services, such as
                  payment processors, customer support, and analytics.
                </li>
                <li>
                  Legal Compliance: We may share your information as required by
                  law, regulation, or legal process, or to respond to lawful
                  requests from public authorities.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Cookies and Similar Technologies
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We may use cookies, web beacons, and other tracking
                  technologies to enhance the user experience, collect usage
                  data, and improve the App's functionality. You can control the
                  use of cookies through your browser settings.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Third-Party Links
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  The App may contain links to third-party websites or services.
                  We are not responsible for the privacy practices or content of
                  these third-party entities. Please review the privacy policies
                  of such third parties before using their services.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Children's Privacy
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  The App is not intended for use by individuals under the age
                  of 18. We do not knowingly collect personal information from
                  children. If you believe we have inadvertently collected
                  information from a child, please contact us immediately.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Changes to this Policy
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We reserve the right to update and revise this Privacy Policy
                  from time to time. We will notify you of any significant
                  changes via the App or through other communication channels.
                  The revised Privacy Policy will become effective upon posting.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Contact Us
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  If you have any questions, concerns, or requests related to
                  this Privacy Policy or our data practices, please contact us
                  at support@escapengr.com
                </li>
              </ol>
              <span className="fontSize14px lh-lg">
                By using the App, you consent to the terms of this Privacy
                Policy and the processing of your personal information as
                described herein. If you do not agree with the terms of this
                Agreement, please do not use the App. <br /> This Privacy Policy
                Agreement constitutes the entire agreement between you and
                Escape Technology Limited regarding the collection, use, and
                disclosure of your information through the App. <br />
                Thank you for reading and understanding our Privacy Policy.
              </span>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Footer />
    </>
  );
};
export default Privacy;

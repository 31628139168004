import React from "react";
import personImg from "../icons/person.png";

const FifthSection = () => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      className="d-flex flex-wrap flex-column-reverse flex-md-row align-items-center justify-content-center wid100  px-4 bgWhite"
    >
      <div className="wid40 wid100Mobile my-md-0 my-4">
        <span className="boldSeven fontSize40px d-block text-md-start text-center lh-1 mb-2">
          The Peace Of Mind Money App
        </span>
        <span className="boldFive d-block wid80 wid100Mobile fontSize14px d-block lh-sm text-md-start text-center">
          We created Escape because we believe everyone deserves financial peace
          of mind, and not worrying if transaction was successful or not.
        </span>
      </div>
      <img src={personImg} alt="" className="ms-9 ms-md-9 wid100Mobile" />
    </div>
  );
};

export default FifthSection;

import React from "react";
import aboutImg from "../icons/aboutPhoto.png";
import {
  AboutList,
  ContactList,
  ImageSection,
  ListItems,
} from "./sectionsListItems";

import sectionImg from "../icons/largeImg.png";
import LocationImg from "../icons/location.png";
import MessageImg from "../icons/message.png";
import PhoneImg from "../icons/phone.png";
import FifthSection from "./fifthSection";
import SixthSection from "./Sixth";

export default function ContactFirstSection() {
  return (
    <>
      <div className="wid60 wid100Mobile mx-auto mt-lg mb-5">
        <span className="d-block boldSeven text-center mx-auto wid60 fontSize44px fontSize48pxMobile blackKsb py-4">
          Serving you around the world
        </span>
      </div>
      <div className="d-block px-md-0 px-3 wid80 wid100Mobile mx-auto mb-5">
        <div className="d-flex flex-wrap flex-md-row flex-column">
          <ContactList
            images={LocationImg}
            header="Address"
            content="8, IgbehinAdun, Street, Pedro Gbagada Lagos, Nigeria"
            colorContent="Headquarters"
            url="https://goo.gl/maps/HGXeEuMDKGZi7aga7"
          />
          <ContactList
            images={MessageImg}
            header="Help & Support"
            content="Email us for help with a current product or service."
            colorContent="he***@es*******.com"
            url="mailto:support@escapengr.com"
          />
          <ContactList
            images={PhoneImg}
            header="Call"
            content="Call us to speak to a member of our team."
            colorContent="+234 902 166 0862"
            url="tel:+2349021660862"
          />
        </div>
      </div>
      <ImageSection image={sectionImg} />
      <div className="my-5"></div>
      <SixthSection />
    </>
  );
}

import React, { useState, useEffect } from "react";
import "../css/TeeStyles.css";
import { Link, useLocation } from "react-router-dom";
import Escape from "../icons/ESCAPE.png";
import Escap from "../icons/ESCAP.png";
import { Icon } from "@iconify/react";

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [mobileNavToggle, setMobileNavToggle] = useState(true);
  const navBtnToggle = () => {
    setMobileNavToggle(!mobileNavToggle);
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar: any = document.querySelector(".navbar");
      const navbarM: any = document.querySelector(".navbarM");

      if (prevScrollPos > currentScrollPos) {
        navbar.style.padding = "20px";
        navbarM.style.padding = "12px";
      } else {
        navbar.style.padding = "10px";
        navbarM.style.padding = "6px";
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  console.log(pathname);

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <nav
        className="boldNine  navbar fixed-top px-4 wid100 contain forntSize14 centerText bgWhiteBlur d-md-flex d-none justify-content-between align-items-center"
        style={{ padding: "20px" }}
      >
        <Link to={"/"} className="wid30M">
          <img src={Escap} alt="navicon" className="wid180px " />
        </Link>
        <div className="d-flex wid20 widHalfMobileTwo  justify-content-around">
          <Link
            to={"/about"}
            className={
              splitLocation[1] === "about"
                ? "escape-color boldSeven fontSize16px"
                : "blackKsb boldSeven fontSize16px"
            }
          >
            About Us
          </Link>
          <Link
            to={"/contact-us"}
            className={
              splitLocation[1] === "contact-us"
                ? "escape-color boldSeven fontSize16px "
                : "blackKsb boldSeven fontSize16px"
            }
          >
            Contact Us
          </Link>
        </div>
      </nav>
      <nav
        className="boldNine navbarM fixed-top px-2 wid100 contain forntSize14 centerText bgWhiteBlur d-md-none d-flex justify-content-between align-items-center"
        style={{ padding: "12px" }}
      >
        <Link to={"/"} className="wid50">
          <img src={Escape} alt="navicon" className=" " />
        </Link>
        <div className=" justify-content-end">
          <button
            onClick={navBtnToggle}
            className="btns boldNine fontSize32px blackKsb"
          >
            {mobileNavToggle ? (
              <Icon icon="mdi:menu" />
            ) : (
              <Icon icon="mdi:close" />
            )}
          </button>
        </div>
      </nav>
      <div
        style={{
          padding: "23px",
          display: mobileNavToggle ? "none" : "block",
          position: "fixed",
          zIndex: 100,
          top: "5%",
          height: "100%",
        }}
        className="wid100 bgWhiteBlur"
      >
        <Link
          to={"/about"}
          className={
            splitLocation[1] === "about"
              ? "escape-color boldSeven fontSize16px d-block text-center mb-2"
              : "blackKsb boldSeven fontSize16px d-block text-center mb-2"
          }
        >
          About Us
        </Link>
        <Link
          to={"/contact-us"}
          className={
            splitLocation[1] === "contact-us"
              ? "escape-color boldSeven fontSize16px d-block text-center mt-5"
              : "blackKsb boldSeven fontSize16px d-block text-center mt-5"
          }
        >
          Contact Us
        </Link>
      </div>
    </>
  );
};
export default Navbar;

import React from "react";
import { Link } from "react-router-dom";

interface Items {
  images: any;
  header: string;
  content: string;
}
interface contactItems {
  images: any;
  header: string;
  content: string;
  colorContent: string;
  url: any;
}
interface sectionThreeItems {
  images: any;
  header: string;
  content: string;
  headerStyle: string;
  contentStyle: string;
}
export const ListItemsOne = ({ images, header, content }: Items) => {
  return (
    <div className="mx-md-2 mx-0 mb-md-0 mb-5 wid30 wid100Mobile ">
      <div className="wid100 d-flex justify-content-md-start justify-content-center">
        <img src={images} alt="" className="wid91px hight108px mb-3" />
      </div>
      <span className="my-1 d-block fontSize20px boldSeven text-md-start text-center">
        {header}
      </span>
      <span className="d-block fontSize16px boldFour text-md-start text-center lh-base">
        {content}
      </span>
    </div>
  );
};

export const ImageSection = ({ image }: any) => {
  return (
    <img src={image} alt="" className="wid100 bgWhite" data-aos="fade-up" />
  );
};

export const ListItemsTwo = ({ images, header, content }: Items) => {
  return (
    <div className="mx-md-2 mx-0 wid30 mb-md-0 mb-4 wid100Mobile escape-bg-box-color p-3 rounded-2">
      <div className="wid100 d-flex justify-content-md-start justify-content-center">
        <img src={images} alt="" className="wid48px mb-3" />
      </div>
      <span className="my-1 d-block  text-md-start text-center fontSize16px boldSeven">
        {header}
      </span>
      <span className="d-block fontSize14px  text-md-start text-center boldFour lh-base">
        {content}
      </span>
    </div>
  );
};

export const ListItems = ({
  images,
  header,
  content,
  headerStyle,
  contentStyle,
}: sectionThreeItems) => {
  return (
    <div className="ms-0 ms-md-4 mt-55 wid40 wid100Mobile py-5 escape-bg-box-color p-3 rounded-2 ">
      <div className="wid100 d-flex justify-content-md-start justify-content-center">
        <img src={images} alt="" className="wid48px mb-3" />
      </div>
      <span className={headerStyle}>{header}</span>
      <span className={contentStyle}>{content}</span>
    </div>
  );
};

export const AboutList = ({ images, header, content }: Items) => {
  return (
    <div className="mx-md-3 mx-auto wid35 wid100Mobile shadow p-3 rounded-2">
      <img src={images} alt="" className="wid48px mb-3" />
      <span className="my-1 d-block fontSize14px boldFive">{header}</span>
      <span className="d-block fontSize12px boldFour lh-base">{content}</span>
    </div>
  );
};

export const ContactList = ({
  images,
  header,
  content,
  colorContent,
  url,
}: contactItems) => {
  return (
    <div className="me-5 wid30 wid100Mobile shadow p-5 rounded-2">
      <img src={images} alt="" className="wid118px mb-6 d-block mx-auto mt-5" />
      <span className="my-1 d-block fontSize16px boldFive text-center">
        {header}
      </span>
      <span className="d-block fontSize14px boldFour lh-base text-center">
        {content}
      </span>
      <Link
        to="javascript:void(0)"
        onClick={() => (window.location.href = url)}
        className="d-block fontSize14px boldFour lh-base text-center escape-color"
      >
        {colorContent}
      </Link>
    </div>
  );
};

import React from "react";
import escapePhone from "../icons/escapePhone.png";
import appleStoreImage from "../icons/Mobile-App-App-Store.webp.png";
import googleImage from "../icons/Mobile-App-Google-Play.webp.png";
import { Link } from "react-router-dom";

const SixthSection = () => {
  return (
    <div className="px-md-5 px-3">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="d-flex align-items-center justify-content-cen wid100 px-4 my-escape-bg-image rounded-5"
      >
        <img
          src={escapePhone}
          alt=""
          className="pe-3 d-none d-md-block wid567px hight547px"
          style={{ marginTop: "-8rem" }}
        />
        <div className="wid55 my-5 py-5">
          <span className="boldSeven d-block fontSize37px d-block lh-sm mb-2 white ">
            Join us and let's rewrite the rules together to make money exchanges
            safe, fast and, free .
          </span>
          <div className="d-flex align-items-center mt-5 justify-content-between wid50">
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.escape.app"
              }
            >
              <img src={googleImage} alt="" />
            </Link>
            <img src={appleStoreImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixthSection;

import React from "react";
import Navbar from "./Navbar";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FifthSection from "./fifthSection";
import SixthSection from "./Sixth";
import Footer from "./footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <SectionOne />
      <SectionTwo />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <Footer />
    </>
  );
};
export default Home;

import React from "react";
import { ListItemsTwo } from "./sectionsListItems";
import frameOne from "../icons/FrameOne.png";
import frameTwo from "../icons/FrameTwo.png";
import frameThree from "../icons/FrameThree.png";

const ThirdSection = () => {
  return (
    <div className="wid100  px-md-5 px-4 py-5 bgWhite">
      <span className="d-block boldSeven excape-black fontSize32px py-3 text-md-start text-center">
        How Escape Works
      </span>
      <div className="pt-5 d-flex flex-md-row flex-column justify-content-between">
        <ListItemsTwo
          images={frameOne}
          header="Open Account"
          content="Setup your account in 2 Mins"
        />
        <ListItemsTwo
          images={frameTwo}
          header="Enter Friend Escape ID"
          content="Enter Friends Escape ID, confirm by affirming Friends Name."
        />
        <ListItemsTwo
          images={frameThree}
          header="Choose Amount And Send"
          content="Enter Escape Amount, and SWIPE TO SEND!!"
        />
      </div>
    </div>
  );
};
export default ThirdSection;

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import { Carousel, Accordion } from "react-bootstrap";
import "./";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  HashRouter,
} from "react-router-dom";
import Home from "./components/Home";
import { useEffect } from "react";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Privacy from "./components/Privacy";
import Private from "./components/Private";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />

        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<Privacy />} />

        <Route path="/protection-of-personal-data" element={<Private />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import { Accordion, Card } from "react-bootstrap";
//import Accordion from "react-bootstrap/Accordion";

const Private = () => {
  return (
    <>
      <Navbar />
      <div className="wid90 mt-6 wid100Mobile px-md-2 px-3 mx-auto">
        <span className="d-block fontSize44px boldSix blackKsb text-center mb-5">
          Welcome to Escape
        </span>
        <span className="text-center wid90 wid100Mobile mx-auto boldFour d-block lh-lg blackKsb fontSize20px mb-5">
          These terms and conditions ("Terms") govern your use of our
          peer-to-peer digital wallet application ("App") and any related
          services provided by us. By using our App, you agree to be bound by
          these Terms. Please read them carefully before proceeding.
        </span>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Acceptance of Terms{" "}
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  By downloading, installing, or using the App, you acknowledge
                  that you have read, understood, and agree to be bound by these
                  Terms.
                </li>
                <li>
                  If you do not agree to these Terms, please refrain from using
                  the App and uninstall it from your device.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Eligibility
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  You must be at least 18 years old and a resident of Nigeria to
                  use the App
                </li>
                <li>
                  By using the App, you represent and warrant that you meet the
                  eligibility criteria mentioned above.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Account Registration
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  You must register an account with accurate and complete
                  information to use the App.
                </li>
                <li>
                  You are solely responsible for maintaining the confidentiality
                  of your account information and password.
                </li>
                <li className="mt-2">
                  You agree to notify us immediately if you suspect any
                  unauthorized use of your account.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                App Usage and Restrictions
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  You may use the App solely for personal, non-commercial
                  purposes in compliance with these Terms and applicable laws
                  and regulations.
                </li>
                <li>
                  You agree not to use the App for any illegal, unauthorized, or
                  fraudulent activities.
                </li>
                <li className="mt-2">
                  You shall not attempt to interfere with the App's security,
                  functionality, or access other users' accounts
                </li>
                <li className="mt-2">
                  You shall not reverse engineer, decompile, or disassemble any
                  part of the App.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Peer-to-Peer Transactions
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  The App facilitates peer-to-peer transactions between users.
                </li>
                <li>
                  You understand and agree that we are not responsible for any
                  disputes, losses, or damages arising from transactions between
                  users.
                </li>
                <li className="mt-2">
                  You acknowledge and accept that the transactions conducted
                  through the App are solely between users, and we do not
                  guarantee the accuracy, quality, or legality of such
                  transactions.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Fees and Charges
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We may charge fees for certain services provided through the
                  App.
                </li>
                <li>
                  You will be notified of any applicable fees and charges before
                  initiating transactions or using specific services.
                </li>
                <li className="mt-2">
                  By using the App, you agree to pay all applicable fees and
                  charges associated with your use of the App.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Privacy Policy
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We are committed to protecting your privacy and personal
                  information.
                </li>
                <li>
                  Our Privacy Policy outlines how we collect, use, and disclose
                  your information. By using the App, you consent to the
                  collection and use of your information as described in our
                  Privacy Policy.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Intellectual Property Rights
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  All intellectual property rights, including copyrights and
                  trademarks, in the App and its content belong to us or our
                  licensors.
                </li>
                <li>
                  You are prohibited from copying, modifying, distributing, or
                  creating derivative works of the App or its content without
                  our prior written consent.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Modifications and Termination
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We reserve the right to modify or terminate the App, its
                  features, or these Terms at any time without prior notice.
                </li>
                <li>
                  Your continued use of the App after any modifications to the
                  Terms constitutes your acceptance of the updated Terms.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4">
                Limitation of Liability
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  We shall not be liable for any direct, indirect, incidental,
                  special, or consequential damages arising from your use of the
                  App.
                </li>
                <li>
                  In no event shall our total liability to you exceed the fees
                  you have paid to us, if any, in the last six months.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <span className="fontSize18px lh-lg boldFive p-4 lh-base">
                Governing Law and Dispute Resolution
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ol type="a" className="fontSize14px lh-lg">
                <li className="mb-2">
                  These Terms shall be governed by and construed in accordance
                  with the laws of Nigeria.
                </li>
                <li>
                  Any dispute arising from or relating to these Terms shall be
                  resolved through negotiation in good faith. If a resolution
                  cannot be reached, the dispute shall be submitted to the
                  exclusive jurisdiction of the courts of Nigeria. <br />
                  <br />
                  Severability If any provision of these Terms is deemed invalid
                  or unenforceable, the remaining provisions shall continue to
                  be valid and enforceable to the fullest extent permitted by
                  law. Entire Agreement These Terms constitute the entire
                  agreement between you and us regarding the use of the App,
                  superseding any prior agreements or understandings. If you
                  have any questions or concerns about these Terms, please
                  contact us at [contact email or address]. <br />
                  <br />
                  By using the App, you acknowledge that you have read,
                  understood, and agree to these Terms and Conditions.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Footer />
    </>
  );
};
export default Private;

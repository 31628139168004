import React, { useState } from "react";
import AboutImg from "../icons/aboutPhoto.png";
import { AboutList, ImageSection, ListItems } from "./sectionsListItems";
import FrameArrow from "../icons/arrow.png";
import finance from "../icons/finance.png";
import userMale from "../icons/userMale.png";
import idea from "../icons/idea.png";
import sectionImg from "../icons/largeImg.png";
import FifthSection from "./fifthSection";
import SixthSection from "./Sixth";

export default function AboutSectionOne() {
  const [ima, setIma] = useState("");
  let image = new Promise((resolve) => resolve(AboutImg));

  async function fatImg() {
    try {
      const Img = await image;
      return Img;
    } catch (error) {
      console.log("err:", error);
    }
  }
  fatImg()
    .then((img: any) => {
      setIma(img);
    })
    .catch((error) => console.log("err:", error));
  return (
    <>
      <div className="wid60 mx-auto mt-lg wid100Mobile">
        <span className="d-block boldSeven text-center mx-auto wid80 wid100Mobile px-md-0 px-2 fontSize44px blackKsb py-4">
          “Simplifying payments to improve people's lives”
        </span>
        <div className="d-flex flex-md-row flex-column px-md-0 px-3">
          <img
            src={ima}
            alt=""
            id="Imagel"
            className="wid428px wid100Mobile me-md-5 me-0 "
          />
          <ListItems
            images={FrameArrow}
            header="Our mission"
            content="Revolutionize the way we make payment used by finance free loving individuals just like you and many more …"
            headerStyle="my-1 d-block text-md-start text-center fontSize24px boldFive"
            contentStyle="d-block fontSize16px  text-md-start text-center boldFour lh-base "
          />
        </div>
        <p className="boldSeven fontSize48px fontSize48pxMobile text-center mt-12 mt-md-12 mb-5 blackKsb">
          Our Core Values
        </p>
      </div>
      <div className="d-block wid80 wid100Mobile mx-auto mb-5">
        <div className="d-flex flex-md-row flex-column px-md-0 px-3">
          <AboutList
            images={userMale}
            header="User centric Approach"
            content="We strive every day to create value for our users."
          />
          <AboutList
            images={finance}
            header="Susceptible and proud, no thanks"
            content="We all work towards one goal: let’s leave our
           ego aside!"
          />
          <AboutList
            images={idea}
            header="Spirit of enterprise"
            content="We reward those who take responsibility for taking that extra step to achieve ambitious goals."
          />
        </div>
      </div>
      <ImageSection image={sectionImg} />
      <div className="my-5"></div>
      <SixthSection />
    </>
  );
}

import React from "react";

const Footer: React.FC = () => {
  return (
    <div className="bg-escape-blue py-5 ps-5 mt-5">
      <span className="fontSize14px boldFour white d-block">
        © 2023 Escape Technologies Limited.
        <span className="d-md-inline d-block"> All Rights Reserved</span>
      </span>
    </div>
  );
};
export default Footer;

import React from "react";
import "../css/TeeStyles.css";
import { Link } from "react-router-dom";
import GoogleImg from "../icons/Google.png";
import AppleImg from "../icons/Apple.png";
import kudaBank from "../icons/kudaBank.png";
import flutterWave from "../icons/flutterWave.png";

const SectionOne = () => {
  var imageBasePath =
    window.location.protocol + "//" + window.location.host + "/icons/young.png";
  return (
    <div
      data-aos="fade-right"
      className="marginTopSmallNavLong wid100 my-image  px-4"
    >
      <div className="pt-4 wid65 wid100Mobile">
        <span
          className="boldSeven fontSize75px lh-sm white d-block pt-4 mb-0"
          data-aos="fade-left"
        >
          Safe fast and free The new way to finance
        </span>
        <span className="boldFive fontSize16px white d-block wid80 wid100Mobile">
          You can send money with Escape for all kinds of things. And, did we
          mention it's super fast? Like in seconds.
        </span>
        <div className="d-flex wid50 wid100Mobile justify-content-between pt-3">
          <Link
            to={"https://play.google.com/store/apps/details?id=com.escape.app"}
            className="wid30Mobile me-3 me-md-0"
          >
            <img src={GoogleImg} alt="" className="wid100" />
          </Link>
          <Link to={"/"} className="wid30Mobile">
            <img src={AppleImg} alt="" className="wid100" />
          </Link>
        </div>
      </div>
      <div className="pt-lg d-flex justify-content-between">
        <div className="d-none d-md-block"></div>
        <div className="d-flex flex-wrap justify-content-md-between justify-content-around align-items-center wid35 wid100Mobile mb-3">
          {/* <span className="white boldFive d-block d-md-flex wid100Mobile fontSize24px">
            Partners
          </span>
          <img src={kudaBank} alt="" className="my-md-0 my-4" />
          <img src={flutterWave} alt="" /> */}
          {/* <img src={kudaBank} alt="" className="mb-4 mb-md-0" />
          <img src={flutterWave} alt="" className="mb-4 mb-md-0" /> */}
        </div>
      </div>
    </div>
  );
};
export default SectionOne;

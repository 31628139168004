import React from "react";
import "../css/TeeStyles.css";
import { Link } from "react-router-dom";
import { ListItemsOne, ImageSection } from "./sectionsListItems";
import itemOne from "../icons/itemOne.png";
import itemTwo from "../icons/itemTwo.png";
import itemThree from "../icons/itemThree.png";
import sectionImg from "../icons/largeImg.png";

const SectionTwo = () => {
  return (
    <>
      <div
        className="wid100  px-4 py-5 bgWhite"
        data-aos="fade-up-right"
        data-aos-duration="1000"
      >
        <span className="d-block text-md-start text-center wid100Mobile boldSeven escape-color fontSize32px py-3">
          Do More With Escape
        </span>
        <div className="pt-5 px-3 d-flex flex-md-row flex-column justify-content-between">
          <ListItemsOne
            images={itemOne}
            header="Bill Payments"
            content="Pay for your cable TV subscription, electricity, water, education, toll, tax, and more."
          />
          <ListItemsOne
            images={itemTwo}
            header="Split Payment"
            content="Keep the spirit of friendship alive, pay your share of the bill and keep everyone smiling."
          />
          <ListItemsOne
            images={itemThree}
            header="Fast & Uninterrupted Transactions"
            content="It's fast and free to send money to any Escape user or Nigerian bank account."
          />
        </div>
      </div>
      <ImageSection image={sectionImg} />
    </>
  );
};
export default SectionTwo;

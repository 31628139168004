import React from "react";
import PhoneImg from "../icons/ESCAPE-5.png";

const FourthSection = () => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      className="d-flex flex-wrap align-items-center justify-content-center wid100  px-4 py-5 bgWhite"
    >
      <img
        src={PhoneImg}
        alt=""
        className="me-9 wid100Mobile me-md-9 mb-4 mb-md-0"
      />
      <div className="wid40 wid100Mobile text-center text-md-start">
        <span className="boldSeven fontSize40px d-block lh-1 mb-4 mb-md-2 ">
          Safely send money to friends and family, no matter where they are!
        </span>
        <span className="boldFive fontSize14px d-block lh-sm">
          Pitching in on meal for outing with friends? Need to send payment for
          emergency purpose? Escape is a best and trusted way to send money to
          friends and family. No worries of bad network, or failed transactions.
        </span>
      </div>
    </div>
  );
};

export default FourthSection;
